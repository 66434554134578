// Remove the config import as we'll use Vite's environment variables
const API_BASE_URL = import.meta.env.VITE_API_URL;
console.log('API Base URL:', API_BASE_URL);

if (!API_BASE_URL) {
  throw new Error('API_BASE_URL is not defined in environment variables');
}

export const getWeightliftingProducts = async () => {
  try {
    console.log('Fetching from:', `${API_BASE_URL}/api/weightlifting-products`);
    
    const response = await fetch(`${API_BASE_URL}/api/weightlifting-products`, {
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    });

    if (!response.ok) {
      const errorBody = await response.text();
      console.error('Error response body:', errorBody);
      throw new Error(`HTTP error! status: ${response.status}, message: ${errorBody}`);
    }

    const data = await response.json();
    console.log('Raw API response:', JSON.stringify(data, null, 2));

    if (!Array.isArray(data)) {
      console.error('Unexpected data format:', data);
      throw new Error('Received data is not an array');
    }

    return data.map(product => ({
      ...product,
      image_path: product.item_image_path || 'img/default-image.png'
    }));
  } catch (error) {
    console.error("Error fetching weightlifting products:", error);
    throw error;
  }
};

export const getWeightliftingProduct = async (productId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/weightlifting-products/${productId}`, {
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    });

    if (!response.ok) {
      const errorBody = await response.text();
      console.error('Error response body:', errorBody);
      throw new Error(`HTTP error! status: ${response.status}, message: ${errorBody}`);
    }

    const data = await response.json();
    console.log('Raw API response:', JSON.stringify(data, null, 2));

    return {
      ...data,
      image_path: data.item_image_path || 'img/default-image.png'
    };
  } catch (error) {
    console.error(`Error fetching weightlifting product with id ${productId}:`, error);
    throw error;
  }
};